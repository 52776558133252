import React from "react";
import styles from "../pages/MEDIALYYFRTAB.module.css";
import { Carousel } from "react-bootstrap";
import VideoTab from './VideoTab';

function BootstrapCarouselTel() {
    const videos = [
        {
          src: '../videos/backmarket.mp4',
          alt: 'BackMarket',
          title: "BackMarket"
        },
        {
          src: '../videos/bricodepot.mp4',
          alt: 'BricoDepot',
          title: "Brico Dépôt"
        },
        {
          src: '../videos/fitnesspark.mp4',
          alt: 'FitnessPark',
          title: "Fitness Park"
        },
        {
          src: '../videos/oceansapart.mp4',
          alt: 'OceansApart',
          title: "OceansApart"
        },
        {
            src: '../videos/blablacar.mp4',
            alt: 'Blablacar',
            title: "Blabacar"
        },
        {
            src: '../videos/ebuyclub.mp4',
            alt: 'eBuyClub',
            title: "eBuyClub"
        },
        {
            src: '../videos/lemon.mp4',
            alt: 'lemon',
            title: "lemon"
        },
        {
            src: '../videos/moncomparateur.mp4',
            alt: 'Mon Comparateur',
            title: "Mon Comparateur"
        },
        {
            src: '../videos/submagic.mp4',
            alt: 'submagic',
            title: "Submagic"
        },
        {
            src: '../videos/optimumnutrition.mp4',
            alt: 'OptimumNutrition',
            title: "Optimum Nutrition"
        },
        {
            src: '../videos/adastra.mp4',
            alt: 'AdAstra',
            title: "Ad Astra"
        },
        {
            src: '../videos/bizzcardz.mp4',
            alt: 'BizzCardz',
            title: "BizzCardz"
        },
        {
            src: '../videos/nutrimuscle.mp4',
            alt: 'nutrimuscle',
            title: "nutrimuscle"
        },
        {
            src: '../videos/teveo.mp4',
            alt: 'Teveo',
            title: "Teveo"
        }
    ]

    return (
        <Carousel fade interval={null} indicators={false}>
            <Carousel.Item>
                <div className={styles.container}>
                    <VideoTab src={videos[0].src}></VideoTab>
                    <div className={styles.textbox_behind}>
                        <div className={styles.textbox}>
                            <h1>Partenariat <b>{videos[0].title}</b></h1>
                            <hr></hr>
                            <br/>
                            <h3><b>+8,8M</b> vues</h3>
                            <h3><b>+10K</b> abonnés</h3>
                            <h3><b>+273K</b> j'aimes</h3>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            
            <Carousel.Item>
                <div className={styles.container}>
                    <VideoTab src={videos[1].src}></VideoTab>
                    <div className={styles.textbox_behind}>
                        <div className={styles.textbox}>
                            <h1>Partenariat <b>{videos[1].title}</b></h1>
                            <hr></hr>
                            <br/>
                            <h3><b>+1,5M</b> vues</h3>
                            <h3><b>+6K</b> abonnés</h3>
                            <h3><b>+11K</b> j'aimes</h3>
                        </div>
                    </div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                <div className={styles.container}>
                    <VideoTab src={videos[2].src}></VideoTab>
                    <div className={styles.textbox_behind}>
                        <div className={styles.textbox}>
                            <h1>Partenariat <b>{videos[2].title}</b></h1>
                            <hr></hr>
                            <br/>
                            <h3><b>+123K</b> vues</h3>
                            <h3><b>+2K</b> j'aimes</h3>
                        </div>
                    </div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                <div className={styles.container}>
                    <VideoTab src={videos[3].src}></VideoTab>
                    <div className={styles.textbox_behind}>
                        <div className={styles.textbox}>
                            <h1>Partenariat <b>{videos[3].title}</b></h1>
                            <hr></hr>
                            <br/>
                            <h3><b>+68,1K</b> vues</h3>
                            <h3><b>+10K</b> abonnés</h3>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BootstrapCarouselTel;