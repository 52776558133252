import styles from "./PRIVACY.module.css";

const PRIVACY = () => {
  return (
    <div className={styles.privacy}>
      <div className={styles.privacyChild} />
      <div className={styles.privacyFrames} />
      <div className={styles.ellipseShape} />
      <div className={styles.frameContainer} />
      <div className={styles.ellipseShape1}>
        <header className={styles.ellipseShape2}>
          <div className={styles.ellipseShapeChild} />
          <h3 className={styles.medialyy}>medialyy</h3>
        </header>
      </div>
      <div className={styles.frameContainer1} />
      <div className={styles.linkFrame}>
        <div className={styles.disclaimerText} />
      </div>
      <main className={styles.adoptionPolicy}>
        <section className={styles.weCareForYourData}>
          <div className={styles.privacyGroup}>
            <button className={styles.rectangleParent}>
              <div className={styles.frameChild} />
              <b className={styles.politiqueDeConfidentialit}>
                Politique de confidentialité
              </b>
            </button>
            <div className={styles.leSiteWebContainer}>
              <p className={styles.leSiteWeb}>
                Le site web Medialyy.fr est détenu par Medialyy, qui est un
                contrôleur de données de vos données personnelles.
              </p>
            </div>
          </div>
          <div className={styles.privacyInfoFrames}>
            <div className={styles.nousAvonsAdoptContainer}>
              <p className={styles.nousAvonsAdopt}>
                Nous avons adopté cette politique de confidentialité, qui
                détermine la manière dont nous traitons les informations
                collectées par Medialyy.fr, qui fournit également les raisons
                pour lesquelles nous devons collecter certaines données
                personnelles vous concernant. Par conséquent, vous devez lire
                cette politique de confidentialité avant d'utiliser le site web
                de Medialyy.fr.
              </p>
            </div>
            <div className={styles.nousPrenonsSoinContainer}>
              <p className={styles.nousPrenonsSoin}>
                Nous prenons soin de vos données personnelles et nous nous
                engageons à en garantir la confidentialité et la sécurité.
              </p>
            </div>
          </div>
          <div className={styles.privacyInfoFrames1}>
            <b className={styles.lesInformationsPersonnelles}>
              Les informations personnelles que nous collectons :
            </b>
            <div className={styles.lorsqueVousVisitezContainer}>
              <p
                className={styles.lorsqueVousVisitez}
              >{`Lorsque vous visitez le Medialyy.fr, nous recueillons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil. En outre, lorsque vous naviguez sur le site, nous recueillons des informations sur les pages web ou les produits individuels que vous consultez, sur les sites web ou les termes de recherche qui vous ont renvoyé au site et sur la manière dont vous interagissez avec le site. `}</p>
            </div>
          </div>
          <div className={styles.privacyInfoFrames2}>
            <div className={styles.nousDsignonsCesContainer}>
              <p className={styles.nousDsignonsCes}>
                Nous désignons ces informations collectées automatiquement par
                le terme "informations sur les appareils". En outre, nous
                pourrions collecter les données personnelles que vous nous
                fournissez (y compris, mais sans s'y limiter, le nom, le prénom,
                l'adresse, les informations de paiement, etc.) lors de
                l'inscription afin de pouvoir exécuter le contrat.
              </p>
            </div>
            <b className={styles.pourquoiTraitonsNousVos}>
              Pourquoi traitons-nous vos données ?
            </b>
          </div>
          <div className={styles.dataSecurity}>
            <div
              className={styles.notrePrioritAbsolue}
            >{`Notre priorité absolue est la sécurité des données des clients et, à ce titre, nous ne pouvons traiter que des données minimales sur les utilisateurs, uniquement dans la mesure où cela est absolument nécessaire pour maintenir le site web. `}</div>
            <div className={styles.lesInformationsCollectes}>
              Les informations collectées automatiquement sont utilisées
              uniquement pour identifier les cas potentiels d'abus et établir
              des informations statistiques concernant l'utilisation du site
              web. Ces informations statistiques ne sont pas autrement agrégées
              de manière à identifier un utilisateur particulier du système.
            </div>
            <div
              className={styles.vousPouvezVisiter}
            >{`Vous pouvez visiter le site web sans nous dire qui vous êtes ni révéler d'informations, par lesquelles quelqu'un pourrait vous identifier comme un individu spécifique et identifiable. Toutefois, si vous souhaitez utiliser certaines fonctionnalités du site web, ou si vous souhaitez recevoir notre lettre d'information ou fournir d'autres détails en remplissant un formulaire, vous pouvez nous fournir des données personnelles, telles que votre e-mail, votre prénom, votre nom, votre ville de résidence, votre organisation, votre numéro de téléphone. `}</div>
            <div className={styles.vousPouvezChoisir}>
              Vous pouvez choisir de ne pas nous fournir vos données
              personnelles, mais il se peut alors que vous ne puissiez pas
              profiter de certaines fonctionnalités du site web. Par exemple,
              vous ne pourrez pas recevoir notre bulletin d'information ou nous
              contacter directement à partir du site web. Les utilisateurs qui
              ne savent pas quelles informations sont obligatoires sont invités
              à nous contacter via contact@medialyy.fr.
            </div>
            <b className={styles.vosDroits}>Vos droits :</b>
            <div className={styles.residentEUInfo}>
              <div className={styles.additionalEllipse} />
              <div className={styles.siVousTesContainer}>
                <p className={styles.siVousTes}>
                  Si vous êtes un résident européen, vous disposez des droits
                  suivants liés à vos données personnelles :
                </p>
                <p className={styles.p}></p>
                <p className={styles.leDroitDtre}>
                  {" "}
                  • Le droit d'être informé.
                </p>
                <p className={styles.leDroitDaccs}> • Le droit d'accès.</p>
                <p className={styles.leDroitDe}>
                  {" "}
                  • Le droit de rectification.
                </p>
                <p className={styles.leDroit}> • Le droit à l'effacement.</p>
                <p className={styles.leDroitDe1}>
                  {" "}
                  • Le droit de restreindre le traitement.
                </p>
                <p className={styles.leDroit1}>
                  {" "}
                  • Le droit à la portabilité des données.
                </p>
                <p className={styles.leDroitDopposition}>
                  {" "}
                  • Le droit d'opposition.
                </p>
                <p className={styles.lesDroitsRelatifs}>
                  {" "}
                  • Les droits relatifs à la prise de décision automatisée et au
                  profilage.
                </p>
                <p className={styles.p1}></p>
                <p className={styles.siVousSouhaitez}>
                  Si vous souhaitez exercer ce droit, veuillez nous contacter
                  via les coordonnées ci-dessous.
                </p>
              </div>
            </div>
            <div className={styles.enOutreSi}>
              En outre, si vous êtes un résident européen, nous notons que nous
              traitons vos informations afin d'exécuter les contrats que nous
              pourrions avoir avec vous (par exemple, si vous passez une
              commande par le biais du site), ou autrement pour poursuivre nos
              intérêts commerciaux légitimes énumérés ci-dessus. En outre,
              veuillez noter que vos informations pourraient être transférées en
              dehors de l'Europe, y compris au Canada et aux États-Unis.
            </div>
          </div>
          <div className={styles.siteSecurity}>
            <div className={styles.notreSiteWeb}>
              Notre site web peut contenir des liens vers d'autres sites web qui
              ne sont pas détenus ou contrôlés par nous. Sachez que nous ne
              sommes pas responsables de ces autres sites web ou des pratiques
              de confidentialité des tiers. Nous vous encourageons à être
              attentif lorsque vous quittez notre site web et à lire les
              déclarations de confidentialité de chaque site web susceptible de
              collecter des informations personnelles.
            </div>
            <b className={styles.scuritDeLinformation}>
              Sécurité de l'information :
            </b>
            <div className={styles.nousScurisonsLes}>
              Nous sécurisons les informations que vous fournissez sur des
              serveurs informatiques dans un environnement contrôlé et sécurisé,
              protégé contre tout accès, utilisation ou divulgation non
              autorisés. Nous conservons des garanties administratives,
              techniques et physiques raisonnables pour nous protéger contre
              tout accès, utilisation, modification et divulgation non autorisés
              des données personnelles sous son contrôle et sa garde. Toutefois,
              aucune transmission de données sur Internet ou sur un réseau sans
              fil ne peut être garantie.
            </div>
            <b className={styles.divulgationLgale}> Divulgation légale :</b>
          </div>
          <div className={styles.privacyInfoFrames3}>
            <div className={styles.nousDivulgueronsToute}>
              Nous divulguerons toute information que nous collectons, utilisons
              ou recevons si la loi l'exige ou l'autorise, par exemple pour nous
              conformer à une citation à comparaître ou à une procédure
              judiciaire similaire, et lorsque nous pensons de bonne foi que la
              divulgation est nécessaire pour protéger nos droits, votre
              sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à
              une demande du gouvernement.
            </div>
            <b className={styles.informationsDeContact}>
              Informations de contact :
            </b>
          </div>
          <div className={styles.siVousSouhaitez1}>
            Si vous souhaitez nous contacter pour comprendre davantage la
            présente politique ou si vous souhaitez nous contacter concernant
            toute question relative aux droits individuels et à vos informations
            personnelles, vous pouvez envoyer un courriel à contact@medialyy.fr.
          </div>
        </section>
      </main>
      <div className={styles.secondEllipse}>
        <div className={styles.informationSecurity} />
      </div>
    </div>
  );
};

export default PRIVACY;
