import styles from "./TERMS.module.css";
const Terms = () => {
  return (
    <div className={styles.terms}>
      <div className={styles.termsChild} />
      <div className={styles.termsItem} />
      <div className={styles.termsInner} />
      <div className={styles.ellipseDiv} />
      <div className={styles.termsChild1} />
      <div className={styles.termsChild2} />
      <div className={styles.termsChild3} />
      <div className={styles.termsChild4} />
      <div className={styles.rectangleDiv} />
      <b className={styles.medialyy}>medialyy</b>
      <div className={styles.termsChild5} />
      <b className={styles.mentionsLgales}>MENTIONS LÉGALES</b>
      <div className={styles.conformmentAuxDispositions}>
        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
        la confiance en l'économie numérique, il est précisé aux utilisateurs du
        site medialyy.fr l'identité des différents intervenants dans le cadre de
        sa réalisation et de son suivi.
      </div>
      <div className={styles.editionDuSite}>EDITION DU SITE</div>
      <div className={styles.hebergement}>HEBERGEMENT</div>
      <div className={styles.directeurDePublication}>
        DIRECTEUR DE PUBLICATION
      </div>
      <div className={styles.lePrsentSiteContainer}>
        <p className={styles.lePrsentSite}>
          Le présent site, accessible à l’URL http://medialyy.fr/ (le « Site »),
          est édité par :
        </p>
        <p className={styles.lePrsentSite}></p>
        <p
          className={styles.lePrsentSite}
        >{`Medialyy, société au capital de 200 euros, inscrite au R.C.S. de NANTERRE sous le numéro Nanterre B 981 215 171, dont le siège social est situé au 118 avenue Jean Jaurès, 92120 Montrouge, France, représenté(e) par Nicolas Occhipinti dûment habilité. `}</p>
      </div>
      <div className={styles.leSiteEst}>
        Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP
        80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).
      </div>
      <div className={styles.leDirecteurDe}>
        Le Directeur de la publication du Site est M. Occhipinti Nicolas.
      </div>
      <div className={styles.parTlphoneContainer}>
        <p className={styles.lePrsentSite}>Par téléphone : +33652149221</p>
        <p className={styles.lePrsentSite}>Par email : contact@medialyy.fr</p>
        <p className={styles.lePrsentSite}>
          Par courrier : 118 avenue Jean Jaurès, 92120 MONTROUGE
        </p>
      </div>
      <div className={styles.nousContacter}>NOUS CONTACTER</div>
    </div>
  );
};

export default Terms;
