import { useCallback } from "react";
import styles from "./MEDIALYYFRTEL.module.css";
import BootstrapCarouselTel from "../components/BootstrapCarouselTel";
import { Link } from "react-router-dom";

const MEDIALYYFRTEL = () => {
  const onGroupButtonClick = useCallback(() => {
    window.location.href = "mailto:contact@medialyy.fr";
  }, []);

  const onGroupButton1Click = useCallback(() => {
    window.location.href = "mailto:contact@medialyy.fr";
  }, []);

  const onRectangleButtonClick = useCallback(() => {
    window.location.href = "mailto:contact@medialyy.fr";
  }, []);

  const onRectangleButton1Click = useCallback(() => {
    window.location.href = "mailto:contact@medialyy.fr";
  }, []);

  const images = [
    {
      src: "../icons/aybl.png",
      className: styles.dropboxIcon,
      alt: "AYBL"
    },
    {
      src: "../icons/fiverr.png",
      className: styles.dropboxIcon,
      alt: "Fiverr"
    },
    {
      src: "../icons/plum.png",
      className: styles.dropboxIcon,
      alt: "Plum"
    },
    {
      src: "../icons/bricodepot.png",
      className: styles.dropboxIcon,
      alt: "BricoDepot"
    },
    {
      src: "../icons/dyson.png",
      className: styles.dropboxIcon,
      alt: "Dyson"
    },
    {
      src: "../icons/fitness_park.png",
      className: styles.dropboxIcon,
      alt: "FitnessPark"
    },
    {
      src: "../icons/blablacar.png",
      className: styles.dropboxIcon,
      alt: "blablacar"
    },
    {
      src: "../icons/backmarket.png",
      className: styles.dropboxIcon,
      alt: "BackMarket"
    },
    {
      src: "../icons/mon-comparateur.png",
      className: styles.dropboxIcon,
      alt: "BackMarket"
    },
    {
      src: "../icons/oceans_apart.png",
      className: styles.dropboxIcon,
      alt: "OceansApart"
    },
    {
      src: "../icons/nordvpn.png",
      className: styles.dropboxIcon,
      alt: "NordVPN"
    },
    {
      src: "../icons/submagic.png",
      className: styles.dropboxIcon,
      alt: "Submagic"
    },
    {
      src: "../icons/tiktok.png",
      className: styles.dropboxIcon,
      alt: "Tiktok"
    },
    {
      src: "../icons/azar.png",
      className: styles.dropboxIcon,
      alt: "Azar"
    },
    {
      src: "../icons/xtb.png",
      className: styles.dropboxIcon,
      alt: "Xtb"
    },
    {
      src: "../icons/optimumnutrition.png",
      className: styles.dropboxIcon,
      alt: "OptimumNutrition"
    },
    {
      src: "../icons/traderepublic.png",
      className: styles.dropboxIcon,
      alt: "TradeRepublic"
    },
    {
      src: "../icons/ebuyclub.png",
      className: styles.dropboxIcon,
      alt: "eBuyClub"
    },
    {
      src: "../icons/wonderfulpistachios.png",
      className: styles.dropboxIcon,
      alt: "WonderfulPistachios"
    },
    {
      src: "../icons/metricool.png",
      className: styles.dropboxIcon,
      alt: "metricool"
    },
    {
      src: "../icons/teveo.png",
      className: styles.dropboxIcon,
      alt: "Teveo"
    },
    {
      src: "../icons/tomba.png",
      className: styles.dropboxIcon,
      alt: "Tomba"
    },
    {
      src: "../icons/nutrimuscle.png",
      className: styles.dropboxIcon,
      alt: "Nutrimuscle"
    },
    {
      src: "../icons/bizzcardz.png",
      className: styles.dropboxIcon,
      alt: "BizzCardz"
    },
  ];
  
  return (
    <div className={styles.medialyyfrTel}>
      <div className={styles.medialyyfrTelChild} />
      <img
        className={styles.medialyyfrTelItem}
        alt=""
        src="../ellipse-37.svg"
      />
      <img
        className={styles.medialyyfrTelInner}
        alt=""
        src="../ellipse-43.svg"
      />
      <img className={styles.ellipseIcon} alt="" src="../ellipse-41.svg" />
      <img
        className={styles.medialyyfrTelChild1}
        alt=""
        src="../ellipse-42.svg"
      />
      <img
        className={styles.medialyyfrTelChild2}
        alt=""
        src="../ellipse-45.svg"
      />
      <img
        className={styles.medialyyfrTelChild3}
        alt=""
        src="../ellipse-38.svg"
      />
      <img
        className={styles.medialyyfrTelChild4}
        alt=""
        src="../ellipse-44.svg"
      />
      <img
        className={styles.medialyyfrTelChild5}
        alt=""
        src="../ellipse-46.svg"
      />
      <div className={styles.footer}>
        <div className={styles.footerChild} />
        <div className={styles.copyrightMedialyyPar}>
          © 2023 Copyright MEDIALYY PAR ORANA
        </div>
        <div className={styles.footer1} />
        <div className={styles.termsParent}>
          <div className={styles.terms}>
            <div className={styles.links}>
            <ul>
                    <li>
                         <Link class={styles.blank_link} to="/terms">Mentions légales</Link>
                    </li>
                    <li>
                          <Link class={styles.blank_link} to="/privacy">Politique de confidentialité</Link>
                    </li>
                   </ul>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.logo}>
              <div className={styles.medialyy}>medialyy</div>
            </div>
            <div className={styles.votre}>
              Votre agence experte en marketing d’influence.<br/><br/>Nicolas :<b className={styles.votre_02}>+33 6 52 14 92 21</b> 
            </div>
          </div>
        </div>
      </div>
      <div className={styles.carousel1}>
        <br/>
        <center><b className={styles.trusted}>Ils nous font confiance</b></center>
        <section className={styles.section}>
          <article className={styles.article}>
            <div>
              <ul className={styles.ul}>
                {images.map((image, i) => (
                  <li className={styles.li}><img src={image.src} className={styles.imglogos} /></li>
                ))}
              </ul>
            </div>
            <div>
              <ul className={styles.ul}>
                {images.map((image, i) => (
                  <li className={styles.li}><img src={image.src} className={styles.imglogos} /></li>
                ))}
              </ul>
            </div>
          </article>
        </section>
      </div>
      <div className={styles.rectangleDiv} />
      <div className={styles.dveloppezVotrePrfrenceDeWrapper}>
        <div className={styles.dveloppezVotrePrfrenceContainer}>
          <p className={styles.dveloppezVotrePrfrenceDe}>
            <span className={styles.dveloppezVotrePrfrence}>
              DÉVELOPPEZ VOTRE IMAGE DE MARQUE GRÂCE AU MARKETING D’INFLUENCE
            </span>
          </p>
          <br/>
          <p className={styles.p}>
            <span>
              <span></span>
            </span>
          </p>
          <p className={styles.nousVousAccompagnonsDansLa}>
            <span>
              <span>
              En effet, c’est l’outil idéal pour développer et faire connaître votre marque au près de nouvelles générations. Nous nous chargeons du suivi de votre projet, de vos performances et vous mettons en relation avec des créateurs de contenus engagés sur TikTok & Insta au travers de leurs contenus (Lifestyle, Sport, Business...).
              </span>
            </span>
          </p>
        </div>
      </div>
      <img
        className={styles.f562f4E2bf4904A5f0Dc31dc91Icon}
        alt=""
        src="../16f562f4e2bf4904a5f0dc31dc9162f7removebgpreview-12@2x.png"
      />
      <div className={styles.imagePlaceholder}>
        <center>
          <BootstrapCarouselTel/>
        </center>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.card1Parent}>
          <div className={styles.card12}>
            <img
              className={styles.iconPlaceholder}
              alt=""
              src="../icon-placeholder.svg"
            />
            <b className={styles.attractifs}>efficace</b>
            <div className={styles.onceYouveSet}>
              Medialyy, l'agence de marketing d'influence par excellence, s'assure que votre campagne soit efficace en tout temps, avec une stratégie claire et des influenceurs de premier choix.
            </div>
          </div>
          <div className={styles.card12}>
            <img
              className={styles.iconPlaceholder}
              alt=""
              src="../icon-placeholder.svg"
            />
            <b className={styles.attractifs}>Polyvalents</b>
            <div className={styles.onceYouveSet}>
            Notre approche innovante nous permet de pouvoir travailler avec des créateurs de contenus de tout horizon, pour offrir à votre marque une portée et un engagement unique.
            </div>
          </div>
          <div className={styles.card12}>
            <img
              className={styles.iconPlaceholder}
              alt=""
              src="../icon-placeholder.svg"
            />
            <b className={styles.attractifs}>professionnels</b>
            <div className={styles.onceYouveSet}>
            Nous sélectionnons rigoureusement les créateurs de contenu les plus pertinents pour votre secteur d'activité et vous accompagnons tout au long de votre campagne pour en maximiser l'impact.
            </div>
          </div>
        </div>
      </div>
      <b className={styles.pourquoiNous}>Pourquoi nous?</b>
      <div className={styles.header}>
        <b className={styles.medialyy1}>medialyy</b>
      </div>
      <div className={styles.content}>
        <b className={styles.dcouvrezMedialyyEtContainer}>
          <span className={styles.d}>d</span>
          <span className={styles.couvrezMedialyyEt}>
            écouvrez Medialyy et nos créateurs
          </span>
        </b>
        <div className={styles.votreAgenceExperte}>
          Votre agence experte en marketing d’influence
        </div>
        <div className={styles.contact}>
          <button
            className={styles.groupParent}
            onClick={onGroupButtonClick}
          >
            <button
              className={styles.groupParent}
              onClick={onGroupButton1Click}
            >
              <div className={styles.rectangleWrapper}>
                <button
                  className={styles.groupChild}
                  disabled
                  onClick={onRectangleButtonClick}
                />
              </div>
              <button
                className={styles.groupItem}
                onClick={onRectangleButton1Click}
              />
            </button>
            <b className={styles.contact1}>Contact</b>
          </button>
        </div>
      </div>
      <b className={styles.faq}>FAQ</b>
      <div className={styles.faqV2M1}>
        <div className={styles.content1}>
          <div className={styles.list}>
            <div className={styles.row}>
              <div className={styles.block}>
                <div className={styles.surface}>
                  <div className={styles.question}>
                    <b className={styles.question1}>
                    Comment faire de l’acquisition client grâce aux réseaux sociaux ?
                    </b>
                  </div>
                  <div className={styles.text}>
                    <div className={styles.blocktext}>
                    ⁃ Identifier les objectifs de l'acquisition<br/>

                    ⁃ Définir une audience cible<br/>

                    ⁃ Créer un contenu attrayant<br/>

                    ⁃ Utiliser des créateurs de contenus<br/>

                    ⁃ Utiliser des liens de suivi<br/>

                    ⁃ Mesurer et analyser les résultats
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row1}>
              <div className={styles.block1}>
                <div className={styles.surface1}>
                  <div className={styles.question}>
                    <b className={styles.question1}>
                    Pourquoi faire appel à une agence de marketing d’influence en 3 points ?
                    </b>
                  </div>
                  <div className={styles.text}>
                    <div className={styles.blocktext}>
                    - Élargir sa portée<br/>
                    - Accroître la notoriété de la marque<br/>
                    - Gagner du temps<br/><br/>

                    En fin de compte, faire appel à une agence de marketing d'influence peut aider les entreprises à atteindre leurs objectifs de marketing et à renforcer leur positionnement sur le marché en s'associant avec des influenceurs de qualité.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.block2}>
                <div className={styles.surface}>
                  <div className={styles.question}>
                    <b className={styles.question1}>
                    Comment mesurer l'efficacité d'une campagne de marketing d'influence ?
                    </b>
                  </div>
                  <div className={styles.text}>
                    <div className={styles.blocktext}>
                    - Taux d'engagement<br/> 
                    - Taux de conversion<br/>
                    - Augmentation de la notoriété de la marque<br/>
                    - Retour sur investissement (ROI)<br/><br/>

                    En mesurant ces indicateurs clés, les agences de marketing d'influence peuvent évaluer l'efficacité d'une campagne et apporter des ajustements pour améliorer les résultats futurs.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.faqV2M11}>
        <div className={styles.content1}>
          <div className={styles.list}>
            <div className={styles.row2}>
              <div className={styles.block3}>
                <div className={styles.surface}>
                  <div className={styles.question}>
                    <b className={styles.question7}>
                      Qu'est-ce que le marketing d'influence et comment cela fonctionne-t-il ?
                    </b>
                  </div>
                  <div className={styles.text_first_box}>
                    <div className={styles.blocktext}>
                      Le marketing d'influence est une forme de marketing qui utilise des personnes influentes (appelées influenceurs) pour promouvoir les produits ou services d'une entreprise. Les influenceurs ont une audience importante sur les réseaux sociaux et peuvent aider les marques à atteindre leur public cible de manière authentique et crédible.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row3}>
              <div className={styles.block4}>
                <div className={styles.surface}>
                  <div className={styles.question8}>
                    <b className={styles.question1}>
                    Pourquoi faire appel à une agence de marketing d’influence en 3 points ?
                    </b>
                  </div>
                  <div className={styles.text_first_box}>
                    <div className={styles.blocktext}>
                    - Élargir sa portée<br/>
                    - Accroître la notoriété de la marque<br/>
                    - Gagner du temps<br/><br/>

                    En fin de compte, faire appel à une agence de marketing d'influence peut aider les entreprises à atteindre leurs objectifs de marketing et à renforcer leur positionnement sur le marché en s'associant avec des influenceurs de qualité.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MEDIALYYFRTEL;
