import { useState } from "react";
import styles from "../pages/MEDIALYYFRTEL.module.css";

function VideoTel({ src }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={styles.videocontainer}>
      <video
        controls
        className={styles.mobile_videos}
        muted
        autoPlay
        onLoadedData={() => setIsLoaded(true)}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoTel;