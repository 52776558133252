import { Routes, Route, Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MEDIALYYFRTEL from "./pages/MEDIALYYFRTEL";
import MEDIALYYFRTAB from "./pages/MEDIALYYFRTAB";
import MEDIALYYFRWEB from "./pages/MEDIALYYFRWEB";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms"


const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const { pathname } = useLocation();
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Component = width < 768 ? MEDIALYYFRTEL : width < 1280 ? MEDIALYYFRTAB : MEDIALYYFRWEB;


  useEffect(() => {
    if (pathname === "/") {
      document.title = "medialyy";
      const metaDesc = document.querySelector('head > meta[name="description"]');
      if (metaDesc) metaDesc.content = "";
    }
  }, [pathname]);

  function App() {
    return (
      <div className="App">
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
      </div>
    )
  }
  return (
      <Routes>
        <Route path="/" element={<Component />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
  );
};

export default App;